import { lazy } from "react";
import ProspectDocView from "../pages/admission-page/prospects/prospect-doc-tracking/prospectDocsView";
import WorkingToDo from "../pages/admission-page/prospects/prospect-reports/workingtodo";
import { Addtodo } from "../pages/admission-page/prospects/prospect-reports/workingtodo/WorkingToDo";
import LoadProspect from "../pages/admission-page/students/load-prospect/load-prospect";
import StudentPortal from "../pages/faculty-manager/active-directory/StudentPortal";
import Maintenance from "../pages/financial-aid-page/maintenance/maintenanceAid";
import CrsEvaluation from "../pages/tools-page/crs-evaluation-setup";
//components export
const Login = lazy(() => import("../components/Login/Login"));
const Ferpa = lazy(() => import("../components/Ferpa/Ferpa"));
const Signout = lazy(() => import("../components/Signout/Signout"));
const PageNotFound = lazy(() =>
  import("../components/PageNotFound/PageNotFound")
);
const ProspectsView = lazy(() =>
  import("../pages/admission-page/prospects/prospect-view/prospectsView")
);
const ProspectsNotesView = lazy(() =>
  import("../pages/admission-page/prospects/prospect-view/prospectsNotes")
);
const ProspectsQuestionsView = lazy(() =>
  import("../pages/admission-page/prospects/prospect-view/prospectQuestions")
);
const FacultyView = lazy(() =>
  import("../pages/faculty-manager/faculty-maintenance/faculty-view/facultyView")
);
const ProspectsCitizenshipView = lazy(() =>
  import("../pages/admission-page/prospects/prospect-view/prospectCitizenship")
);
const ProspectInternationalView = lazy(() =>
  import(
    "../pages/admission-page/prospects/prospect-view/prospectInternational"
  )
);
const ProspectsMergeView = lazy(() =>
  import("../pages/admission-page/prospects/prospect-view/prospectMerge")
);
const StudentDocView = lazy(() =>
  import("../pages/admission-page/students/student-view/studentDocsView")
);
const Addprospects = lazy(() =>
  import("../pages/admission-page/prospects/add-prospects/add-prospect")
);
const Addfaculty = lazy(() =>
  import("../pages/faculty-manager/faculty-maintenance/add-faculty/add-faculty")
);
const ProspectReportDocumentTracking = lazy(() =>
  import(
    "../pages/admission-page/prospects/prospect-reports/document-tracking/document-tracking"
  )
);
const ImportProspect = lazy(() =>
  import("../pages/tools-page/import-prospect")
);
const FindOrganization = lazy(() =>
  import(
    "../pages/admission-page/organization/search-organization/search-organization"
  )
);
const AddOrganization = lazy(() =>
  import(
    "../pages/admission-page/organization/add-organization/add-organization"
  )
);
const Byor = lazy(() =>
  import("../pages/admission-page/prospects/prospect-reports/byor/Byor")
);
const GradeSummary = lazy(() =>
  import(
    "../pages/registration-page/reports/grade-summary-reports/grade-summary/grade-summary"
  )
);
const FullTimeEquivalent = lazy(() =>
  import(
    "../pages/registration-page/reports/full-time-equivalent/full-time-equivalent"
  )
);
const TimeSlotReferences = lazy(() =>
  import(
    "../pages/registration-page/offering/setup/time-slot-referrences/time-slot-referrences"
  )
);
const AddInternationalInfo = lazy(() =>
  import(
    "../pages/admission-page/prospects/prospect-international/prospect-international"
  )
);
const Dashboard = lazy(() => import("../pages/dashboard-page/index"));
const CrossListedCourses = lazy(() =>
  import(
    "../pages/registration-page/offering/cross-listed-courses/cross-listed"
  )
);
//Lookups Imports
const Colleges = lazy(() =>
  import("../pages/admission-page/lookups/colleges/Colleges")
);
const CollegeTabs = lazy(() =>
  import("../pages/admission-page/lookups/colleges/CollegeTabs")
);
const HighSchool = lazy(() =>
  import("../pages/admission-page/lookups/highschool/HighSchool")
);
const HighSchoolTabs = lazy(() =>
  import("../pages/admission-page/lookups/highschool/HighSchoolTabs")
);
const LetterSequences = lazy(() =>
  import("../pages/admission-page/lookups/letterSequences/LetterSequences")
);
//student imports
const StudentsView = lazy(() =>
  import("../pages/admission-page/students/student-view/StudentView")
);
const AddStudents = lazy(() =>
  import("../pages/admission-page/students/add-students/add-students")
);
const StudentAccess = lazy(() =>
  import("../pages/admission-page/students/student-access/student-access")
);
const StudentSevis = lazy(() =>
  import("../pages/admission-page/students/student-sevis/student-sevis")
);
const StudentLaptopTracking = lazy(() =>
  import(
    "../pages/admission-page/students/student-laptop-tracking/student-laptop-tracking"
  )
);
const StudentEvaluationApplicant = lazy(() =>
  import(
    "../pages/admission-page/students/student-evaluations/applicant/applicant"
  )
);
const StudentEvaluationSetup = lazy(() =>
  import("../pages/admission-page/students/student-evaluations/setup/setup")
);
const StudentRisk = lazy(() =>
  import("../pages/admission-page/students/student-risk/student-risk")
);
const StudentReportsCorrespondence = lazy(() =>
  import(
    "../pages/admission-page/students/student-reports/correspondence/correspondence"
  )
);
const StudentInternationalView = lazy(() =>
  import("../pages/admission-page/students/student-view/studentInterational")
);

// Popup Page
const SearchContact = lazy(() =>
  import("../pages/admission-page/contacts/search-contact")
);
// layout components export
const LoginLayoutRoute = lazy(() => import("./loginlayoutroute"));
const MainLayoutRoute = lazy(() => import("./mainProtectedlayoutroute"));

//Registration Module

const OfficialTranscript = lazy(() =>
  import("../pages/registration-page/academic/transcript/official")
);
const AdvisorTranscript = lazy(() =>
  import("../pages/registration-page/academic/transcript/advisor")
);
const DataEntry = lazy(() =>
  import("../pages/registration-page/attendance/data-entry")
);
const DataRangeEntry = lazy(() =>
  import(
    "../pages/registration-page/attendance/data-range-entry/dataRangeEntry"
  )
);
const RegistrationCatalogMaintenance = lazy(() =>
  import("../pages/registration-page/catalog/maintenance/index")
);
const RegistrationCatalogCopy = lazy(() =>
  import("../pages/registration-page/catalog/copy-catalog/index")
);
const AttendenceReport = lazy(() =>
  import(
    "../pages/registration-page/attendance/attendance-reports/attendence-byor/AttendenceReport"
  )
);
const StudentByor = lazy(() =>
  import(
    "../pages/registration-page/degree-audit/reports/student-byor/StudentByor"
  )
);
const Printoffering = lazy(() =>
  import("../pages/registration-page/offering/offering-print/Printoffering")
);
const PrintRoaster = lazy(() =>
  import("../pages/registration-page/offering/roaster/PrintRoaster")
);
const HoldandDegreeReport = lazy(() =>
  import(
    "../pages/registration-page/reports/degree-reports/HoldandDegreeReport"
  )
);
const Directory = lazy(() =>
  import("../pages/registration-page/reports/directory/directory")
);
const IncompleteCourses = lazy(() =>
  import(
    "../pages/registration-page/degree-audit/reports/incomplete-courses/incompleteCourses"
  )
);
const ScheduleRegistration = lazy(() =>
  import(
    "../pages/registration-page/schedule-registration/schedule-registration"
  )
);
const RegistrationAudit = lazy(() =>
  import("../pages/registration-page/degree-audit/RegistrationAudit")
);
const RegistrationAcademicDegrees = lazy(() =>
  import("../pages/registration-page/academic/degrees/index")
);
const RegistrationAcademicMaintenance = lazy(() =>
  import("../pages/registration-page/academic/maintenance/maintenance-index")
);
const RegistrationAcademicTransfer = lazy(() =>
  import("../pages/registration-page/academic/transfer/index")
);
const GradeCards = lazy(() =>
  import(
    "../pages/registration-page/reports/grade-reports/grade-cards/grade-cards"
  )
);
const EnrollmentVerification = lazy(() =>
  import(
    "../pages/registration-page/reports/enrollment-verification-reports/enrollment-verification"
  )
);
const MasterCourses = lazy(() =>
  import("../pages/registration-page/master-courses/mastercourses")
);

const GradeEntry = lazy(() =>
  import("../pages/registration-page/academic/grades/grade-entry/grade-entry")
);
const GradeEntryy = lazy(() =>
  import("../pages/registration-page/academic/grades/grade-entry/grade-entryy")
);
const Offerings = lazy(() =>
  import("../pages/registration-page/offering/setup/offerings/offering")
);
const RegistrationAcademicSchedule = lazy(() =>
  import("../pages/registration-page/academic/schedule/index")
);
const NumericGradeEntry = lazy(() =>
  import(
    "../pages/registration-page/academic/grades/number-grade-entry/number-grade-entry"
  )
);
const AddNumericGradeEntry = lazy(() =>
  import(
    "../pages/registration-page/academic/grades/number-grade-entry/addNumberGradeEntry"
  )
);
const TutionAndRefunds = lazy(() =>
  import("../pages/registration-page/offering/setup/tution-and-refunds/index")
);
const RegistrationAcademicRegister = lazy(() =>
  import("../pages/registration-page/academic/register/index")
);
const AuthPortalGrade = lazy(() =>
  import("../pages/registration-page/academic/grades/authPortalgrade/index")
);
const OfferingCourse = lazy(() =>
  import(
    "../pages/registration-page/offering/offering-maintenance/addOfferingCourse/offering-index"
  )
);
const StudentEvaluation = lazy(() =>
  import(
    "../pages/registration-page/degree-audit/Student-Evaluation/student-evaluation-tabs/student-evaluation-index"
  )
);
const TabParameters = lazy(() =>
  import("../pages/registration-page/academic/register/index")
);
const UnOfficial = lazy(() =>
  import("../pages/registration-page/academic/unofficial")
);
// Billing Module

const MassStudentRegistration = lazy(() =>
  import(
    "../pages/registration-page/academic/mass-student-registration/mass-student-registration-index"
  )
);

// Billing Module
const Authorize = lazy(() => import("../pages/billing-page/authorize/index"));
const BillingMaintenance = lazy(() =>
  import("../pages/billing-page/billing-maintenance/billing-maintenance-index")
);
const CashierEntry = lazy(() =>
  import("../pages/billing-page/cashier-entry/cashierEntry")
);
const BillingBatch = lazy(() =>
  import("../pages/billing-page/billing-batch/billing-batch-index")
);
const PaymentPlanTypeSetup = lazy(() =>
  import(
    "../pages/billing-page/payment-plans/setup/paymentplantypesetup/paymentplantypesetup"
  )
);
const GeneralLedger = lazy(() =>
  import(
    "../pages/billing-page/billing-accounting/post-options/general-ledger/general-ledger"
  )
);

const AccountsPayable = lazy(() =>
  import(
    "../pages/billing-page/billing-accounting/post-options/accounts-payable/accounts-payable-index"
  )
);

const creating = lazy(() =>
  import(
    "../pages/billing-page/billing-accounting/re-create-options/accountable-payable/recreate-accountpayable"
  )
);


const OnlinePayments = lazy(() =>
  import(
    "../pages/billing-page/reports-menu/onlinePayments/onlinePayments"
  )
);

const Form = lazy(() =>
  import(
    "../pages/billing-page/reports-menu/t2202Aform/index"
  )

);
const T4aForm = lazy(() =>
  import(
    "../pages/billing-page/reports-menu/t4aform/t4aform"
  )
);

const AgingReports = lazy(() =>
  import(
    "../pages/billing-page/reports-menu/agingReports/aging")
);
const PaymentPlansMaintenance = lazy(() =>
  import("../pages/billing-page/payment-plans/maintenance/maintenance")
);
const ReportsByor = lazy(() =>
  import(
    "../pages/billing-page/reports-menu/BYOR/byor-index")
);
const Reports1098TExport = lazy(() =>
  import(
    "../pages/billing-page/reports-menu/1098TExport/1098TExport-index")
);
const Statements = lazy(() =>
  import(
    "../pages/billing-page/reports-menu/Statements/statements-index")
);

const ReportsTForm = lazy(() =>
  import("../pages/billing-page/reports-menu/1098tform/1098tform"));

const AlterPlans = lazy(() =>
  import(
    "../pages/billing-page/payment-plans/alter-plan/alter-plan-index"
  ));
import(("../pages/billing-page/reports-menu/1098tform/1098tform"));
const ReCreateOptions = lazy(() =>
  import("../pages/billing-page/billing-accounting/re-create-options/general-ledger/general-ledger"))
const ChangeAddress = lazy(() =>
  import("../pages/billing-page/change-address/change-address"));

//Financial Aid
const Transfer = lazy(() =>
  import(
    "../pages/financial-aid-page/transfer/transferIndex")
);
const COD = lazy(() =>
  import(
    "../pages/financial-aid-page/COD/cod")
);
const Fisap = lazy(() =>
  import("../pages/financial-aid-page/Reports/fisap/index")
);
const Single = lazy(() =>
  import(
    "../pages/financial-aid-page/packaging/single-index")
);
const Enrollment = lazy(() =>
  import(
    "../pages/financial-aid-page/Reports/statusAndLoad/enrollment/enrollment")
);
const BYOR = lazy(() =>
  import(
    "../pages/financial-aid-page/Reports/byor/byor")
);

const Budgets = lazy(() =>
  import(
    "../pages/financial-aid-page/packaging/Prioritized/budgets/budgets-index")

);
const WorkStudyMaintenance = lazy(() =>
  import(
    "../pages/financial-aid-page/work-study/work-study-maintenance/maintenance-index")
);
const Workstudysetup = lazy(() =>
  import(
    "../pages/financial-aid-page/setup/work-study-setup/workStudySetup")
);
const AwardReference = lazy(() =>
  import(
    "../pages/financial-aid-page/setup/award-reference/awardReference")
);
const AwardMethodology = lazy(() =>
  import(
    "../pages/financial-aid-page/packaging/Prioritized/awardMethodology/awardMethodology")
);

const AwardLetters = lazy(() =>
  import(
    "../pages/financial-aid-page/Reports/awardLetters/awardLetters")
);
const Package = lazy(() =>
  import(
    "../pages/financial-aid-page/packaging/Prioritized/package/package-index"
  ));
const Holds = lazy(() =>
  import("../pages/financial-aid-page/holds/holds"));


const YearlySetUp = lazy(() => import("../pages/financial-aid-page/setup/yearly-setup/yearly-setup-index"));

const MaintenanceAid = lazy(() => import("../pages/financial-aid-page/maintenance/maintenanceAid"));

const LoadPell = lazy(() =>
  import(
    "../pages/financial-aid-page/setup/loadPell/loadPell"
  ))

const StudentStatus = lazy(() => import("../pages/financial-aid-page/student-status/student-status-index"));
const LoanLogExitInterview = lazy(() =>
  import(
    "../pages/financial-aid-page/loanLog-exitInteview/index"
  ))
const PackageCriteria = lazy(() =>
  import(
    "../pages/financial-aid-page/packaging/Prioritized/package-criteria/packageCriteria"
  ))
const LifeTimeLimits = lazy(() => import("../pages/financial-aid-page/packaging/Prioritized/life-time-limits/life-time-limits-index"));
const StudentNotes = lazy(() => import("../pages/financial-aid-page/studentNotes/student-notes"))
//Faculty AND Manager
const EMailTemplates = lazy(() => import("../pages/faculty-manager/lookup-table-options/e-mail-templates/e-mail-templates"))
const Configuration = lazy(() => import("../pages/faculty-manager/configuration/configuration-index"))
const CODImport = lazy(() => import("../pages/tools-page/import/financialaid/codimport"))
const DoctrackAutoLoad = lazy(() => import("../pages/tools-page/import/financialaid/docTrack-autoload/index"))
const TableMaintanance = lazy(() => import("../pages/faculty-manager/lookup-table-options/table-maintanance/table-maintanance-index"))
const UserDefinedFieldSetup = lazy(() => import("../pages/faculty-manager/lookup-table-options/userDefinedSetup/setUp"))
const PortalUrlRef = lazy(() => import("../pages/faculty-manager/lookup-table-options/portal-url-ref/portal-url-ref-index"))
const RoleAdministration = lazy(() => import("../pages/faculty-manager/users/role-administration/index"))
const CourseManagementRole = lazy(() => import("../pages/faculty-manager/CAMS-Portal/course-management-roles/index"))
const FPAdminRoleAccess = lazy(() => import("../pages/faculty-manager/users/fp-admin-role/admin"))
const ViewErrorLog = lazy(() => import("../pages/faculty-manager/view-error-log/viewError"))
const GovernmentalSAP = lazy(() => import("../pages/faculty-manager/lookup-table-options/govermentalSAP/index"))
const InstitutionalSAP = lazy(() => import("../pages/faculty-manager/lookup-table-options/institutionalSAP/index"))
const CurrencyTypes = lazy(() => import("../pages/faculty-manager/multy-currency/currency-types/currencyType"))
const GradApp = lazy(() => import("../pages/faculty-manager/CAMS-Portal/Portal-Configuration/grad-app-configuration/grad-app"))
const IPLockout = lazy(() => import("../pages/faculty-manager/CAMS-Portal/Portal-Configuration/IP-Lockout/IP-Lockout"))
const CustomGradeEntry = lazy(() => import('../pages/faculty-manager/lookup-table-options/custom-grade-entry/custom-grade-entry-index'))
const SpeedeSetup = lazy(() => import("../pages/faculty-manager/lookup-table-options/speede-setup/index"))
const MiscConfiguration = lazy(() => import("../pages/faculty-manager/CAMS-Portal/Portal-Configuration/misc-configuration/index"))
const Exchange = lazy(() => import('../pages/faculty-manager/multy-currency/exchange-rates/exchange'))
const UserAdministration = lazy(() => import("../pages/faculty-manager/users/userAdministration-index"))
const Grades = lazy(() => import("../pages/faculty-manager/rebuild/registration/grades/grade"))
const EnrollmentCounts = lazy(() => import("../pages/faculty-manager/rebuild/registration/enrollmentCounts/enrollmentCount"))
const RebuildRepeats = lazy(() => import("../pages/faculty-manager/rebuild/registration/rebuild-repeats/rebuild-index"))
const Configure = lazy(() => import("../pages/faculty-manager/multy-currency/configure/configure"))
const Indicator = lazy(() => import("../pages/faculty-manager/lookup-table-options/risk-indicator-setup/indicator"))
const TestScoreSetup = lazy(() => import("../pages/faculty-manager/lookup-table-options/test-score-table-setup/test-score-setup"))
const StateGlossoryTable = lazy(() => import("../pages/faculty-manager/lookup-table-options/state-reports-lookup/state-glossory-table"))
const GradeGroups = lazy(() => import("../pages/faculty-manager/lookup-table-options/grade-groups/gradeGroups"))
const DocumentTaracking = lazy(() => import("../pages/faculty-manager/lookup-table-options/document-tracking-setup/docTrack"))
const Semaphores = lazy(() => import("../pages/faculty-manager/semaphores/semaphores/semaphores"))
const Registration = lazy(() => import("../pages/faculty-manager/semaphores/registration/registration"))
const LDAP = lazy(() => import("../pages/faculty-manager/LDAP/ldap-index"))
const TermCalendar = lazy(() => import("../pages/faculty-manager/lookup-table-options/term-calendar/index"))
const TransactionDocuments = lazy(() => import("../pages/faculty-manager/lookup-table-options/transactionDocuments/index"))
const TransformationTemplates = lazy(() => import("../pages/faculty-manager/import-export/transformationTemplate"))
const CreditCardsTransfer = lazy(() => import("../pages/faculty-manager/CAMS-Portal/Transfer/credit-cards/credit-cards-index"))
const DeleteStudents = lazy(() => import("../pages/faculty-manager/Student-Utilities/deleteStudents"))
const PaymentConfiguration = lazy(() => import("../pages/faculty-manager/CAMS-Portal/Portal-Configuration/payment-configuration/paymentConfiguration"))
const LocationSetup = lazy(() => import("../pages/faculty-manager/lookup-table-options/location-lookup/location-setup/location-setup-index"))
const ManageReports = lazy(() => import("../pages/faculty-manager/manage-reports/manageReports"))

//Tools
const ISIRDirect = lazy(() => import("../pages/tools-page/import/financialaid/isirdirect/isirdirect"))
const CrsEvaluationSetup = lazy(() => import("../pages/tools-page/crs-evaluation-setup/index"))
const TranscriptBatches = lazy(() => import("../pages/tools-page/processes/registration-module/transcriptbatches/transcriptbatch"))
const BillingProcesses = lazy(() => import("../pages/tools-page/processes/Billing-Module/billing-processes/index"))
const GenerateExamIDs = lazy(() => import("../pages/tools-page/processes/registration-module/generate-exam-ids/examid"))
const StatusChanges = lazy(() => import("../pages/tools-page/processes/registration-module/status-changes/index"))
const NewsFeed = lazy(() => import("../pages/tools-page/newsFeed/newsFeedList"))
const AddProspectActivity = lazy(() => import("../pages/tools-page/processes/admissionsModule/addProspectActivity/addProspectActivity"))
const AddStudentActivity = lazy(() => import("../pages/tools-page/processes/admissionsModule/addStudentActivity/addStudentActivity"))
const CountySetup = lazy(() => import("../pages/faculty-manager/lookup-table-options/location-lookup/county-setup/countySetup"))
const Portal = lazy(() => import("../pages/faculty-manager/CAMS-Portal/portal-news/portal"))
const FacultyConfig = lazy(() => import("../pages/faculty-manager/CAMS-Portal/Portal-Configuration/faculty-configuration/facultyConfig"))
const Application = lazy(() => import("../pages/faculty-manager/CAMS-Portal/Portal-Configuration/application-Configuration/application"))
const NYSSIRIS = lazy(() => import("../pages/tools-page/export/newYorkStateReports/nysSiris/nys-Siris"))
const Student = lazy(() => import("../pages/faculty-manager/CAMS-Portal/Portal-Configuration/student-configuration/student"))
const SSNMigration = lazy(() => import("../pages/faculty-manager/SsnMigration/migration"))
const SearchPublisher = lazy(() => import("../pages/faculty-manager/IntegrationPlatform/PublisherEventSetup/searchPublisher"))
const KhedsExport = lazy(() => import("../pages/tools-page/export/kansasStateReports/kheds-Export/khedsExport"))
const LatePaymentFees = lazy(() => import('../pages/tools-page/processes/Billing-Module/late-payment-fees/latePaymentFees'))
const TexasStateReports = lazy(() => import("../pages/tools-page/export/texas-state-reports/texas-state-reports-tabs/texas-faculty-parameter"))
const CODMessage = lazy(() => import("../pages/tools-page/import/financialaid/cod-Message/codMessage"))
const SpeedeCollegeTranscript = lazy(() => import('../pages/tools-page/export/registration/speede-college-transcripts/speedCollegeTranscripts'))
const EDEAwards = lazy(() => import("../pages/tools-page/import/financialaid/ede-awards/ede-awards"))
const MergeOffer = lazy(() => import("../pages/tools-page/processes/registration-module/merge-offering/mergeOffer"))
const MassUpdateAudit = lazy(() => import("../pages/tools-page/processes/registration-module/mass-update-audit/index"))
const MassAddAudit = lazy(() => import("../pages/tools-page/processes/registration-module/mass-add-audit/index"))
const ApplyTexasImport = lazy(() => import("../pages/tools-page/import/admissions/applytexasimport"))
const BulkImport = lazy(() => import("../pages/tools-page/import/admissions/bulkImport"))
const GainfulEmploymentExport = lazy(() => import("../pages/tools-page/export/financial-aid/gainful-employment-export/gainfulEmployment"))
const NSLCData = lazy(() => import("../pages/tools-page/export/financial-aid/nslc-export/nslc-export"))
const CODExports = lazy(() => import("../pages/tools-page/export/financial-aid/cod-export/cod-export-index"))
const CbmCEStudents = lazy(() => import("../pages/tools-page/export/texas-state-reports/texas-cbmce-students/texas-cbmce-student-tabs/cbmce-student-parameter"))
const StudentCensus = lazy(() => import("../pages/tools-page/export/texas-state-reports/texax-student-census/texas-student-census"))
const TXFinAidDBSys = lazy(() => import("../pages/tools-page/export/texas-state-reports/tx-fin-aid-dbsys/txfinaiddbsys"))
const TestScoreMap = lazy(() => import("../pages/tools-page/import/admissions/test-score-mapping/testScore"))
const CensusStudentSchedule = lazy(() => import("../pages/tools-page/export/texas-state-reports/censusstudentschedule/censusstdschedule"))
const RCSRecCourseSequence = lazy(() => import("../pages/tools-page/export/texas-state-reports/rcsreccourseseq/rcsreccourseseq"))

const R2T4 = lazy(() => import("../pages/tools-page/processes/financial-aid/return-to-title/index"))
const MassUpdate = lazy(() => import('../pages/tools-page/processes/registration-module/mass-update-status/mass-update'))
const PowerFaids = lazy(() => import("../pages/tools-page/import/financialaid/power-Faids/powerfaids"))
const EndSemStudents = lazy(() => import('../pages/tools-page/export/texas-state-reports/end-sem-students/endSemStudents'))
const Cbm009Graduation = lazy(() => import('../pages/tools-page/export/texas-state-reports/cbm009-graduation/cbm009-graduation'))
const Cbm002SuccessInit = lazy(() => import('../pages/tools-page/export/texas-state-reports/cbm002-successinit/index'))
const studentSchedule = lazy(() => import('../pages/tools-page/export/texas-state-reports/student-schedule/studentSchedule'))
const NoShowAwardRemove = lazy(() => import("../pages/tools-page/processes/financial-aid/no-show-remove/no-show-award-remove"))
const Powerfaids = lazy(() => import("../pages/tools-page/export/financial-aid/powerfaids/powerfaids"))
const SAP = lazy(() => import("../pages/tools-page/processes/registration-module/sap/sap-index"))
const CbmCEClasses = lazy(() => import("../pages/tools-page/export/texas-state-reports/texas-cbmce-classes/cbmce-classes"))
const AsnImport = lazy(() => import("../pages/tools-page/import/canadianImports/asn-import/asnImport"))
const SpeedeCollegeTranscriptImport = lazy(() => import("../pages/tools-page/import/registration/speede-college-transcript/speede-college-transcript"))
const DegreeVerify = lazy(() => import("../pages/tools-page/export/registration/degree-verify/degreeVerify"))
const StudentPictureLoad = lazy(() => import("../pages/tools-page/pictureAccess/studentPictureLoad"));
const TestScoreImp = lazy(() => import("../pages/tools-page/import/admissions/test-scores-import/testScore-import"))
const ProspectStudentImp = lazy(() => import("../pages/tools-page/import/admissions/prospect-student-import/prospectStudentImportIndex"))
const FacultyPictureLoad = lazy(() => import("../pages/tools-page/pictureAccess/facultyPictureLoad"));
const GlobalSearch = lazy(() => import("../pages/tools-page/global-search/globalSearch"))
const BillingImport = lazy(() => import("../pages/tools-page/import/billing/billing-import/index"))
const ChangePassword = lazy(() => import("../components/ChangePassword/changePassword"));
const ExpirePassword = lazy(() => import("../components/ChangePassword/expirePassword"));
const ResetPassword = lazy(() => import("../components/ChangePassword/resetPassword"));
const DataQuery = lazy(() => import("../pages/tools-page/data-query/data-query"))

//student life imports
const Amenities = lazy(() =>
  import("../pages/student-life-page/housing/properties/amenities/index")
);
const AddAmenities = lazy(() =>
  import("../pages/student-life-page/housing/properties/amenities/add-amenities/add-amenities")
);

//Properties
const SearchProperties = lazy(() =>
  import("../pages/student-life-page/housing/properties/propertiesIndex")
);

const AddProperties = lazy(() =>
  import("../pages/student-life-page/housing/properties/index")
);

const ViewProperties = lazy(() =>
  import("../pages/student-life-page/housing/properties/view/index")
);

//Meal Plan
const SearchMealPlan = lazy(() =>
  import("../pages/student-life-page/meal-plan/index")
);

const AddMealPlan = lazy(() =>
  import("../pages/student-life-page/meal-plan/add-meal-plan")
);

const ViewMealPlan = lazy(() =>
  import("../pages/student-life-page/meal-plan/view-meal-plan")
);

//Forms
const SearchForm = lazy(() =>
  import("../pages/tools-page/forms/index")
);


//Forms
const CustomForm = lazy(() =>
  import("../pages/tools-page/forms/index")
);

// Housing Periods
const HousingPeriods = lazy(() => import("../pages/student-life-page/housing/housing-periods/HousingPeriods"));

const routesConfig = [
  {
    component: Login,
    exact: true,
    path: "/",
    Layout: LoginLayoutRoute,
  },
  {
    component: Login,
    exact: true,
    path: "/login",
    Layout: LoginLayoutRoute,
  },
  {
    component: Signout,
    exact: true,
    path: "/signout",
    Layout: LoginLayoutRoute,
  },
  {
    component: Ferpa,
    exact: true,
    path: "/ferpa",
    Layout: LoginLayoutRoute,
  },
  {
    component: ExpirePassword,
    exact: true,
    path: "/password-expire",
    Layout: LoginLayoutRoute,
  },
  {
    component: ResetPassword,
    exact: true,
    path: "/reset-password",
    Layout: LoginLayoutRoute,
  },
  {
    component: ChangePassword,
    exact: true,
    path: "/change-password",
    Layout: MainLayoutRoute,
  },
  {
    component: ProspectsView,
    exact: true,
    path: "/admissions/prospects/select-prospects",
    Layout: MainLayoutRoute,
  },
  {
    component: ProspectsNotesView,
    exact: true,
    path: "/admissions/prospects/prospect-notes",
    Layout: MainLayoutRoute,
  },
  {
    component: ProspectsQuestionsView,
    exact: true,
    path: "/admissions/prospects/prospect-questions",
    Layout: MainLayoutRoute,
  },
  {
    component: FacultyView,
    exact: true,
    path: "/system-manager/faculty-maintanance",
    Layout: MainLayoutRoute,
  },
  {
    component: ProspectsCitizenshipView,
    exact: true,
    path: "/admissions/prospects/prospect-citizenship",
    Layout: MainLayoutRoute,
  },
  {
    component: ProspectInternationalView,
    exact: true,
    path: "/admissions/prospects/prospect-search",
    Layout: MainLayoutRoute,
  },
  {
    component: ProspectsMergeView,
    exact: true,
    path: "/admissions/prospects/prospect-merge",
    Layout: MainLayoutRoute,
  },
  {
    component: ProspectDocView,
    exact: true,
    path: "/admissions/prospects/document-tracking",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentDocView,
    exact: true,
    path: "/admissions/students/document-tracking",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentDocView,
    exact: true,
    path: "/financial-aid/document-tracking",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentDocView,
    exact: true,
    path: "/registration/academic/document-tracking",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentDocView,
    exact: true,
    path: "/billing/document-tracking",
    Layout: MainLayoutRoute,
  },
  {
    component: ProspectReportDocumentTracking,
    exact: true,
    path: "/admissions/students/reports/document-tracking",
    Layout: MainLayoutRoute,
  },
  {
    component: Addprospects,
    exact: true,
    path: "/admissions/prospects/add-prospects",
    Layout: MainLayoutRoute,
  },
  {
    component: Addfaculty,
    exact: true,
    path: "/system-manager/faculty/add-faculty",
    Layout: MainLayoutRoute,
  },
  {
    component: FindOrganization,
    exact: true,
    path: "/admission/organization",
    Layout: MainLayoutRoute,
  },
  {
    component: SearchContact,
    exact: true,
    path: "/admission/contacts",
    Layout: MainLayoutRoute,
  },
  {
    component: AddOrganization,
    exact: true,
    path: "/admissions/organization/add-organization",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentsView,
    exact: true,
    path: "/admissions/students/select-student",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentAccess,
    exact: true,
    path: "/admissions/students/student-access",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentSevis,
    exact: true,
    path: "/admissions/students/student-sevis",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentLaptopTracking,
    exact: true,
    path: "/admissions/students/laptop-tracking",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentReportsCorrespondence,
    exact: true,
    path: "/admissions/students/reports/correspondence",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentInternationalView,
    exact: true,
    path: "/admissions/students/student-search",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentInternationalView,
    exact: true,
    path: "/registration/student-search",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentInternationalView,
    exact: true,
    path: "/billing/student-search",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentInternationalView,
    exact: true,
    path: "/financial-aid/student-search",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentInternationalView,
    exact: true,
    path: "/tools/student-search",
    Layout: MainLayoutRoute,
  },
  // ~~~~~~~Lookups Routes Start~~~~~~~~~
  {
    component: Colleges,
    exact: true,
    path: "/admissions/lookups/college",
    Layout: MainLayoutRoute,
  },
  {
    component: CollegeTabs,
    exact: true,
    path: "/admissions/lookups/college-tabs",
    Layout: MainLayoutRoute,
  },
  {
    component: HighSchool,
    exact: true,
    path: "/admissions/lookups/highschool",
    Layout: MainLayoutRoute,
  },
  {
    component: HighSchoolTabs,
    exact: true,
    path: "/admissions/lookups/highschool-tabs",
    Layout: MainLayoutRoute,
  },
  {
    component: LetterSequences,
    exact: true,
    path: "/admissions/lookups/letter-sequences",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentEvaluationApplicant,
    exact: true,
    path: "/admissions/students/evaluations/applicant",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentEvaluationSetup,
    exact: true,
    path: "/admissions/students/evaluations/setup",
    Layout: MainLayoutRoute,
  },

  // ~~~~~~~Lookups Routes End~~~~~~~~~

  {
    component: AddStudents,
    exact: true,
    path: "/admissions/students/add-students",
    Layout: MainLayoutRoute,
  },
  {
    component: LoadProspect,
    exact: true,
    path: "/admissions/students/load-prospect",
    Layout: MainLayoutRoute,
  },
  {
    component: WorkingToDo,
    exact: true,
    path: "/admissions/students/reports/working-to-do",
    Layout: MainLayoutRoute,
  },
  {
    component: Addtodo,
    exact: true,
    path: "/admissions/students/report/Add-to-do",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentRisk,
    exact: true,
    path: "/admissions/students/student-risk",
    Layout: MainLayoutRoute,
  },
  {
    component: Dashboard,
    exact: true,
    path: "/home",
    Layout: MainLayoutRoute,
  },
  // {
  //   component: Byor,
  //   exact: true,
  //   path: "/admissions/students/reports",
  //   Layout: MainLayoutRoute,
  // },
  {
    component: OfficialTranscript,
    exact: true,
    path: "/registration/academic/transcript/official",
    Layout: MainLayoutRoute,
  },
  {
    component: AdvisorTranscript,
    exact: true,
    path: "/registration/academic/transcript/advisor",
    Layout: MainLayoutRoute,
  },

  {
    component: DataEntry,
    exact: true,
    path: "/registration/attendance/data-entry",
    Layout: MainLayoutRoute,
  },
  {
    component: DataRangeEntry,
    exact: true,
    path: "/registration/attendance/data-range-entry",
    Layout: MainLayoutRoute,
  },
  {
    component: RegistrationCatalogMaintenance,
    exact: true,
    path: "/registration/catalog/maintenance",
    Layout: MainLayoutRoute,
  },
  {
    component: RegistrationAcademicDegrees,
    exact: true,
    path: "/registration/academic/degrees",
    Layout: MainLayoutRoute,
  },
  {
    component: RegistrationAcademicTransfer,
    exact: true,
    path: "/registration/academic/transfer",
    Layout: MainLayoutRoute,
  },
  {
    component: RegistrationAcademicRegister,
    exact: true,
    path: "/registration/academic/register/register-search",
    Layout: MainLayoutRoute,
  },
  {
    component: UnOfficial,
    exact: true,
    path: "/registration/academic/unofficial/parameters",
    Layout: MainLayoutRoute,
  },
  {
    component: TabParameters,
    exact: true,
    path: "/registration/academic/register/register-parameters",
    Layout: MainLayoutRoute,
  },
  {
    component: RegistrationCatalogCopy,
    exact: true,
    path: "/registration/catalog/copy-catalog",
    Layout: MainLayoutRoute,
  },
  {
    component: RegistrationAudit,
    exact: true,
    path: "/registration/degree-audit/degree-audit-setup",
    Layout: MainLayoutRoute,
  },

  {
    component: AttendenceReport,
    exact: true,
    path: "/registration/attendance/reports/BYOR",
    Layout: MainLayoutRoute,
  },
  {
    component: GradeSummary,
    exact: true,
    path: "/registration/reports/grade-summary",
    Layout: MainLayoutRoute,
  },
  {
    component: FullTimeEquivalent,
    exact: true,
    path: "/registration/reports/full-time-equivalent",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentByor,
    exact: true,
    path: "/registration/degree-audit/reports/student-BYOR",
    Layout: MainLayoutRoute,
  },
  {
    component: Printoffering,
    exact: true,
    path: "/registration/offering/print-offering",
    Layout: MainLayoutRoute,
  },
  {
    component: PrintRoaster,
    exact: true,
    path: "/registration/offering/roster/print-roster",
    Layout: MainLayoutRoute,
  },
  {
    component: HoldandDegreeReport,
    exact: true,
    path: "/registration/reports/hold-degree-Report",
    Layout: MainLayoutRoute,
  },
  {
    component: Directory,
    exact: true,
    path: "/registration/reports/directory",
    Layout: MainLayoutRoute,
  },
  {
    component: IncompleteCourses,
    exact: true,
    path: "/registration/degree-audit/incomplete-courses",
    Layout: MainLayoutRoute,
  },
  {
    component: Offerings,
    exact: true,
    path: "/registration/offering/setup/offerings",
    Layout: MainLayoutRoute,
  },
  {
    component: MasterCourses,
    exact: true,
    path: "/registration/master-courses",
    Layout: MainLayoutRoute,
  },
  {
    component: ScheduleRegistration,
    exact: true,
    path: "/registration/schedule-registration",
    Layout: MainLayoutRoute,
  },
  {
    component: UnOfficial,
    exact: true,
    path: "/registration/academic/unofficial",
    Layout: MainLayoutRoute,
  },
  {
    component: RegistrationAcademicMaintenance,
    exact: true,
    path: "/registration/academic/transcript/maintenance",
    Layout: MainLayoutRoute,
  },
  {
    component: GradeCards,
    exact: true,
    path: "/registration/reports/grade-reports/grade-cards",
    Layout: MainLayoutRoute,
  },
  {
    component: GradeEntry,
    exact: true,
    path: "/registration/academic/grades/grade-entry",
    Layout: MainLayoutRoute,
  },
  {
    component: EnrollmentVerification,
    exact: true,
    path: "/registration/reports/enrollment-verification",
    Layout: MainLayoutRoute,
  },
  {
    component: GradeEntryy,
    exact: true,
    path: "/registration/academic/grades/grade-entryy",
    Layout: MainLayoutRoute,
  },
  {
    component: NumericGradeEntry,
    exact: true,
    path: "/registration/academic/grades/numeric-grade-entry",
    Layout: MainLayoutRoute,
  },
  {
    component: AddNumericGradeEntry,
    exact: true,
    path: "/registration/academic/grades/numeric-grade-entryy",
    Layout: MainLayoutRoute,
  },
  {
    component: CrossListedCourses,
    exact: true,
    path: "/registration/offering/cross-listed-courses",
    Layout: MainLayoutRoute,
  },
  {
    component: RegistrationAcademicSchedule,
    exact: true,
    path: "/registration/academic/schedule",
    Layout: MainLayoutRoute,
  },
  {
    component: TimeSlotReferences,
    exact: true,
    path: "/registration/offering/setup/time-slots",
    Layout: MainLayoutRoute,
  },
  {
    component: TutionAndRefunds,
    exact: true,
    path: "/registration/offering/setup/tuition-and-refunds",
    Layout: MainLayoutRoute,
  },
  {
    component: AuthPortalGrade,
    exact: true,
    path: "/registration/academic/grades/authPortalgrade",
    Layout: MainLayoutRoute,
  },
  {
    component: OfferingCourse,
    exact: true,
    path: "/registration/offering/offering-maintenance",
    Layout: MainLayoutRoute,
  },
  {
    component: CashierEntry,
    exact: true,
    path: "/billing/cashier-entry",
    Layout: MainLayoutRoute,
  },
  {
    component: MassStudentRegistration,
    exact: true,
    path: "/registration/mass-student-registration",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentEvaluation,
    exact: true,
    path: "/registration/degree-audit/student-evaluation",
    Layout: MainLayoutRoute,
  },
  {
    component: Authorize,
    exact: true,
    path: "/billing/authorize",
    Layout: MainLayoutRoute,
  },
  {
    component: PaymentPlanTypeSetup,
    exact: true,
    path: "/billing/payment-plans/setup/paymentplantypesetup",
    Layout: MainLayoutRoute,
  },
  {
    component: BillingMaintenance,
    exact: true,
    path: "/billing/maintenance",
    Layout: MainLayoutRoute,
  },
  {
    component: BillingBatch,
    exact: true,
    path: "/billing/batch",
    Layout: MainLayoutRoute,
  },
  {
    component: AgingReports,
    exact: true,
    path: "/billing/reports-menu/aging-reports",
    Layout: MainLayoutRoute,
  },
  {
    component: OnlinePayments,
    exact: true,
    path: "/billing/reports-menu/online-payments",
    Layout: MainLayoutRoute,
  },
  {
    component: T4aForm,
    exact: true,
    path: "/billing/reports-menu/t4a-form",
    Layout: MainLayoutRoute,
  },
  {
    component: Form,
    exact: true,
    path: "/billing/reports-menu/t2202A-form",
    Layout: MainLayoutRoute,
  },
  {
    component: PaymentPlansMaintenance,
    exact: true,
    path: "/billing/payment-plans/maintenance",
    Layout: MainLayoutRoute,
  },
  {
    component: GeneralLedger,
    exact: true,
    path: "/billing/billing-accounting/post-options/general-ledger",
    Layout: MainLayoutRoute,
  },
  {
    component: AddInternationalInfo,
    exact: true,
    path: "/admissions/prospects/prospect-international",
    Layout: MainLayoutRoute,
  },
  {
    component: AccountsPayable,
    exact: true,
    path: "/billing/billing-accounting/post-options/accounts-payable",
    Layout: MainLayoutRoute,
  },
  {
    component: creating,
    exact: true,
    path: "/billing/billing-accounting/re-create-options/accountable-payable",
    Layout: MainLayoutRoute,
  },
  {
    component: ReportsTForm,
    exact: true,
    path: "/billing/reports-menu/1098T-form",
    Layout: MainLayoutRoute,
  },
  {
    component: ReportsByor,
    exact: true,
    path: "/billing/reports-menu/byor",
    Layout: MainLayoutRoute,
  },
  {
    component: Statements,
    exact: true,
    path: "/billing/reports-menu/statements",
    Layout: MainLayoutRoute,
  },
  {
    component: AlterPlans,
    exact: true,
    path: "/billing/payment-plans-alter-plan",
    Layout: MainLayoutRoute,
  },
  {
    component: Reports1098TExport,
    exact: true,
    path: "/billing/reports-menu/1098T-export",
    Layout: MainLayoutRoute,
  },
  {
    component: ReCreateOptions,
    exact: true,
    path: "/billing/billing-accounting/re-create-options/general-ledger",
    Layout: MainLayoutRoute,
  },
  {
    component: ChangeAddress,
    exact: true,
    path: "/billing/change-address",
    Layout: MainLayoutRoute,
  },
  {
    component: ChangeAddress,
    exact: true,
    path: "/admission/students/change-address",
    Layout: MainLayoutRoute,
  },
  {
    component: ChangeAddress,
    exact: true,
    path: "/registration/academic/change-address",
    Layout: MainLayoutRoute,
  },
  {
    component: Transfer,
    exact: true,
    path: "/financial-aid/transfer",
    Layout: MainLayoutRoute,
  },
  {
    component: COD,
    exact: true,
    path: "/financial-aid/cod",
    Layout: MainLayoutRoute,

  },
  {
    component: Fisap,
    exact: true,
    path: "/financial-aid/report/fisap",
    Layout: MainLayoutRoute,
  },
  {
    component: Single,
    exact: true,
    path: "/financial-aid/Single",
    Layout: MainLayoutRoute,
  },
  {
    component: Enrollment,
    exact: true,
    path: "/financial-aid/report/status-and-load/enrollement",
    Layout: MainLayoutRoute,
  },
  {
    component: BYOR,
    exact: true,
    path: "/financial-aid/report/byor",
    Layout: MainLayoutRoute,
  },
  {
    component: Budgets,
    exact: true,
    path: "/financial-aid/packaging/prioritized/budgets",
    Layout: MainLayoutRoute,
  },
  {
    component: WorkStudyMaintenance,
    exact: true,
    path: "/financial-aid/work-study/maintenance",
    Layout: MainLayoutRoute,
  },
  {
    component: Workstudysetup,
    exact: true,
    path: "/financial-aid/setup/work-study-setup",
    Layout: MainLayoutRoute,
  },
  {
    component: AwardReference,
    exact: true,
    path: "/financial-aid/setup/award-reference",
    Layout: MainLayoutRoute,
  },


  {
    component: AwardLetters,
    exact: true,
    path: "/financial-aid/report/award-letters",
    Layout: MainLayoutRoute,
  },

  {
    component: Package,
    exact: true,
    path: "/financial-aid/packaging/prioritized/package",
    Layout: MainLayoutRoute,
  },

  {
    component: AwardMethodology,
    exact: true,
    path: "/financial-aid/packaging/prioritized/award-methodology",
    Layout: MainLayoutRoute,
  },


  {
    component: YearlySetUp,
    exact: true,
    path: "/financial-aid/setup/yearly-setup",
    Layout: MainLayoutRoute,
  },
  {
    component: MaintenanceAid,
    exact: true,
    path: "/financial-aid/maintenance",
    Layout: MainLayoutRoute,

  },
  {
    component: LoadPell,
    path: "/financial-aid/setup/load-pell",
    Layout: MainLayoutRoute,
  },
  {
    component: Holds,
    path: "/financial-aid/holds",
    Layout: MainLayoutRoute,
  },
  {
    component: Holds,
    path: "/registration/academic/holds",
    Layout: MainLayoutRoute,
  },
  {
    component: Holds,
    path: "/billing/holds",
    Layout: MainLayoutRoute,
  },
  {
    component: LoanLogExitInterview,
    path: "/financial-aid/loan-log-exit-interview",
    Layout: MainLayoutRoute,
  },
  {
    component: PackageCriteria,
    exact: true,
    path: "/financial-aid/packaging/prioritized/package-criteria",
    Layout: MainLayoutRoute,
  },
  {
    component: LifeTimeLimits,
    path: "/financial-aid/packaging/prioritized/life-time-limits",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentNotes,
    exact: true,
    path: "/financial-aid/student-notes",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentNotes,
    exact: true,
    path: "/admissions/students/student-notes",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentNotes,
    exact: true,
    path: "/registration/academic/student-notes",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentNotes,
    exact: true,
    path: "/billing/student-notes",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentStatus,
    exact: true,
    path: "/financial-aid/student-status",
    Layout: MainLayoutRoute,
  },

  {
    component: StudentStatus,
    exact: true,
    path: "/admissions/students/student-status",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentStatus,
    exact: true,
    path: "/registration/academic/student-status",
    Layout: MainLayoutRoute,
  },
  //Faculty AND Manager
  {
    component: EMailTemplates,
    exact: true,
    path: "/system-manager/lookup-table-Options/e-mail-templates",
    Layout: MainLayoutRoute,
  },
  {
    component: UserDefinedFieldSetup,
    exact: true,
    path: "/system-manager/lookup-table-options/user-defined-field-setup",
    Layout: MainLayoutRoute,
  },

  {
    component: Configuration,
    exact: true,
    path: "/system-manager/configuration",
    Layout: MainLayoutRoute,
  },
  {
    component: CustomGradeEntry,
    exact: true,
    path: "/system-manager/lookup-table-options/custom-grade-entry",
    Layout: MainLayoutRoute,
  },
  {
    component: PortalUrlRef,
    exact: true,
    path: "/system-manager/lookup-table-options/portal-url-ref",
    Layout: MainLayoutRoute
  },
  {
    component: UserAdministration,
    exact: true,
    path: "/system-manager/users/user-adminstration",
    Layout: MainLayoutRoute,
  },
  {
    component: Exchange,
    exact: true,
    path: "/system-manager/multicurrency/exchange-rates",
    Layout: MainLayoutRoute,
  },
  {
    component: RoleAdministration,
    exact: true,
    path: "/system-manager/users/role-adminstration",
    Layout: MainLayoutRoute,
  },
  {
    component: FPAdminRoleAccess,
    exact: true,
    path: "/system-manager/users/fp-admin-role-access",
    Layout: MainLayoutRoute,
  },
  {
    component: ViewErrorLog,
    exact: true,
    path: "/system-manager/view-error-log",
    Layout: MainLayoutRoute,
  },
  {
    component: TableMaintanance,
    exact: true,
    path: "/system-manager/lookup-table-options/table-maintanance",
    Layout: MainLayoutRoute,
  },
  {
    component: Grades,
    exact: true,
    path: "/system-manager/rebuild/registration/grades",
    Layout: MainLayoutRoute,
  },
  {
    component: EnrollmentCounts,
    exact: true,
    path: "/system-manager/rebuild/registration/enrollmentCounts",
    Layout: MainLayoutRoute,
  },
  {
    component: RebuildRepeats,
    exact: true,
    path: "/system-manager/rebuild/registration/rebuild-repeats",
    Layout: MainLayoutRoute,
  },
  {

    component: CurrencyTypes,
    exact: true,
    path: "/system-manager/multicurrency/currency-types",
    Layout: MainLayoutRoute,
  },
  {
    component: Configure,
    exact: true,
    path: "/system-manager/multicurrency/configure",
    Layout: MainLayoutRoute,
  },
  {
    component: Indicator,
    exact: true,
    path: "/system-manager/lookup-table-options/risk-indicator-setup",
    Layout: MainLayoutRoute,
  },
  {
    component: TestScoreSetup,
    exact: true,
    path: "/system-manager/lookup-table-options/test-score-table-setup",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentPortal,
    exact: true,
    path: "/system-manager/active-directory/student-portal",
    Layout: MainLayoutRoute,
  },
  {
    component: StateGlossoryTable,
    exact: true,
    path: "/system-manager/lookup-table-options/state-reports-lookup",
    Layout: MainLayoutRoute,
  },
  {
    component: GradeGroups,
    exact: true,
    path: "/system-manager/lookup-table-options/grade-groups",
    Layout: MainLayoutRoute,
  },
  {
    component: DocumentTaracking,
    exact: true,
    path: "/system-manager/lookup-table-options/document-tracking-setup",
    Layout: MainLayoutRoute,
  },

  {
    component: Semaphores,
    exact: true,
    path: "/system-manager/semaphores/semaphore",
    Layout: MainLayoutRoute,
  },
  {
    component: Registration,
    exact: true,
    path: "/system-manager/semaphores/registration",
    Layout: MainLayoutRoute,
  },
  {
    component: LDAP,
    exact: true,
    path: "/system-manager/ldap",
    Layout: MainLayoutRoute,
  },
  {
    component: TransformationTemplates,
    exact: true,
    path: "/system-manager/import-export/transformation-templates",
    Layout: MainLayoutRoute,
  },
  {
    component: CreditCardsTransfer,
    exact: true,
    path: "/system-manager/cams-portal/transfer/credit-cards",
    Layout: MainLayoutRoute,
  },
  {

    component: GradApp,
    exact: true,
    path: "/system-manager/cams-portal/portal-configuration/grad-app-configuration",
    Layout: MainLayoutRoute,
  },
  {
    component: GovernmentalSAP,
    exact: true,
    path: "/system-manager/lookup-table-options/governmental-sap",
    Layout: MainLayoutRoute,
  },
  {
    component: InstitutionalSAP,
    exact: true,
    path: "/system-manager/lookup-table-options/institutional-sap",
    Layout: MainLayoutRoute,
  },
  {
    component: TermCalendar,
    exact: true,
    path: "/system-manager/lookup-table-options/term-calendar",
    Layout: MainLayoutRoute,
  },
  {
    component: TransactionDocuments,
    exact: true,
    path: "/system-manager/lookup-table-options/transaction-documents",
    Layout: MainLayoutRoute,
  },
  {
    component: SpeedeSetup,
    exact: true,
    path: "/system-manager/lookup-table-options/speede-setup",
    Layout: MainLayoutRoute,
  },
  {
    component: MiscConfiguration,
    exact: true,
    path: "/system-manager/cams-portal/portal-configuration/misc-configuration",
    Layout: MainLayoutRoute,
  },
  {
    component: IPLockout,
    exact: true,
    path: "/system-manager/cams-portal/portal-configuration/ip-lockout",
    Layout: MainLayoutRoute,
  },
  {
    component: DeleteStudents,
    exact: true,
    path: "/system-manager/student-utilities/delete-students",
    Layout: MainLayoutRoute,
  },
  {
    component: PaymentConfiguration,
    exact: true,
    path: "/system-manager/cams-portal/portal-configuration/payment-configuration",
    Layout: MainLayoutRoute,
  },
  {
    component: ManageReports,
    exact: true,
    path: "/system-manager/managereport",
    Layout: MainLayoutRoute,

  },
  {
    component: DoctrackAutoLoad,
    exact: true,
    path: "/tools/import/financial-aid/docktrack-autoload",
    Layout: MainLayoutRoute,
  },
  {
    component: CODImport,
    exact: true,
    path: "/tools/import/financial-aid/cod-import",
    Layout: MainLayoutRoute,
  },
  {
    component: CrsEvaluationSetup,
    exact: true,
    path: "/tools/crs-evaluation-setup",
    Layout: MainLayoutRoute,
  },
  {
    component: NewsFeed,
    exact: true,
    path: "/tools/elements-news-feed",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentPictureLoad,
    exact: true,
    path: "/tools/picture-access/student-picture-load",
    Layout: MainLayoutRoute,
  },
  {
    component: FacultyPictureLoad,
    exact: true,
    path: "/tools/picture-access/faculty-picture-load",
    Layout: MainLayoutRoute,
  },
  {
    component: Application,
    exact: true,
    path: "/system-manager/cams-portal/portal-configuration/application-configuration",
    Layout: MainLayoutRoute,
  },
  {
    component: CountySetup,
    exact: true,
    path: "/system-manager/lookup-table-options/location-lookup/county-setup",
    Layout: MainLayoutRoute,
  },
  {
    component: FacultyConfig,
    exact: true,
    path: "/system-manager/cams-portal/portal-configuration/faculty-configuration",
    Layout: MainLayoutRoute,
  },
  {
    component: NYSSIRIS,
    exact: true,
    path: "/tools/export/newyork-state-reports/nys-siris",
    Layout: MainLayoutRoute,
  },
  {
    component: Student,
    exact: true,
    path: "/system-manager/cams-portal/portal-configuration/student-configuration",
    Layout: MainLayoutRoute,
  },
  {
    component: Portal,
    exact: true,
    path: "/system-manager/cams-portal/portal-news",
    Layout: MainLayoutRoute,
  },
  {
    component: CourseManagementRole,
    exact: true,
    path: "/system-manager/cams-portal/course-management-roles",
    Layout: MainLayoutRoute,
  },
  {
    component: KhedsExport,
    exact: true,
    path: "/tools/export/kansas-state-reports/kheds-export",
    Layout: MainLayoutRoute,
  },
  {
    component: LatePaymentFees,
    exact: true,
    path: "/tools/processes/billing-module/late-payment-fees",
    Layout: MainLayoutRoute,
  },
  {
    component: TexasStateReports,
    exact: true,
    path: "/tools/export/texas-state-reports/cbm008-faculty",
    Layout: MainLayoutRoute,
  },
  {
    component: LocationSetup,
    exact: true,
    path: "/system-manager/lookup-table-options/location-lookup/location-setup",
    Layout: MainLayoutRoute,
  },
  {
    component: BillingImport,
    exact: true,
    path: "/tools/import/billing/billing-import",
    Layout: MainLayoutRoute,
  },
  {
    component: AddStudentActivity,
    exact: true,
    path: "/tools/processes/admission-module/add-student-activity",
    Layout: MainLayoutRoute,
  },
  {
    component: AddProspectActivity,
    exact: true,
    path: "/tools/processes/admission-module/add-prospect-activity",
    Layout: MainLayoutRoute,
  },
  {
    component: CODMessage,
    exact: true,
    path: "/tools/import/financial-aid/cod-message",
    Layout: MainLayoutRoute,
  },
  {
    component: EDEAwards,
    exact: true,
    path: "/tools/import/financial-aid/ede-awards",
    Layout: MainLayoutRoute,
  },
  {
    component: ApplyTexasImport,
    exact: true,
    path: "/tools/import/admissions/apply-texas-import",
    Layout: MainLayoutRoute,
  },
  {
    component: GainfulEmploymentExport,
    exact: true,
    path: "/tools/export/financial-aid/gainful-employment-export",
    Layout: MainLayoutRoute,
  },
  {
    component: GenerateExamIDs,
    exact: true,
    path: "/tools/processes/registration-module/generate-exam-ids",
    Layout: MainLayoutRoute,
  },
  {
    component: StatusChanges,
    exact: true,
    path: "/tools/processes/registration-module/status-changes",
    Layout: MainLayoutRoute,
  },
  {
    component: SpeedeCollegeTranscript,
    exact: true,
    path: "/tools/export/registration/speede-college-transcript",
    Layout: MainLayoutRoute,
  },
  {
    component: MergeOffer,
    exact: true,
    path: "/tools/processes/registration-module/merge-offering",
    Layout: MainLayoutRoute,
  },
  {
    component: ISIRDirect,
    exact: true,
    path: "/tools/import/financial-aid/isir-direct",
    Layout: MainLayoutRoute,
  },
  {
    component: TestScoreMap,
    exact: true,
    path: "/tools/import/admissions/test-score-mapping",
    Layout: MainLayoutRoute,
  },
  {
    component: MassUpdate,
    exact: true,
    path: "/tools/processes/registration-module/mass-update-status",
    Layout: MainLayoutRoute,
  },
  {
    component: Powerfaids,
    exact: true,
    path: "/tools/export/financial-aid/powerfaids",
    Layout: MainLayoutRoute,
  },
  {
    component: NSLCData,
    exact: true,
    path: "/tools/export/financial-aid/nsc-export",
    Layout: MainLayoutRoute
  },
  {
    component: TranscriptBatches,
    exact: true,
    path: "/tools/processes/registration-module/transcript-batches",
    Layout: MainLayoutRoute,
  },
  {

    component: MassUpdateAudit,
    exact: true,
    path: "/tools/processes/registration-module/mass-update-audit",
    Layout: MainLayoutRoute,
  },
  {
    component: CbmCEStudents,
    exact: true,
    path: "/tools/export/texas-state-reports/cbm00a-ce-students",
    Layout: MainLayoutRoute,
  },
  {
    component: CODExports,
    exact: true,
    path: "/tools/export/financial-aid/cod-export",
    Layout: MainLayoutRoute,
  },
  {
    component: R2T4,
    exact: true,
    path: "/tools/processes/financial-aid/r2t4",
    Layout: MainLayoutRoute,
  },
  {
    component: StudentCensus,
    exact: true,
    path: "/tools/export/texas-state-reports/cbm0c1-student-census",
    Layout: MainLayoutRoute,
  },
  {
    component: SAP,
    exact: true,
    path: "/tools/processes/registration-module/sap",
    Layout: MainLayoutRoute,
  },
  {
    component: PowerFaids,
    exact: true,
    path: "/tools/import/financial-aid/powerfaids",
    Layout: MainLayoutRoute,
  },
  {
    component: EndSemStudents,
    exact: true,
    path: "/tools/export/texas-state-reports/cbm0e1-end-sem-students",
    Layout: MainLayoutRoute,
  },
  {
    component: studentSchedule,
    exact: true,
    path: "/tools/export/texas-state-reports/cbm00s-student-schedule",
    Layout: MainLayoutRoute,
  },
  {
    component: NoShowAwardRemove,
    exact: true,
    path: "/tools/processes/financial-aid/no-show-remove",
    Layout: MainLayoutRoute,
  },
  {
    component: MassAddAudit,
    exact: true,
    path: "/tools/processes/registration-module/mass-add-status",
    Layout: MainLayoutRoute,
  },
  {
    component: BillingProcesses,
    exact: true,
    path: "/tools/processes/billing-module/billing-processes",
    Layout: MainLayoutRoute,
  },
  {
    component: BulkImport,
    exact: true,
    path: "/tools/import/admissions/bulk-import",
    Layout: MainLayoutRoute,
  },
  {
    component: CbmCEClasses,
    exact: true,
    path: "/tools/export/texas-state-reports/cbm00c-ce-classes",
    Layout: MainLayoutRoute,
  },
  {
    component: AsnImport,
    exact: true,
    path: "/tools/import/canadian-imports/asn-import",
    Layout: MainLayoutRoute,
  },
  {
    component: TXFinAidDBSys,
    exact: true,
    path: "/tools/export/texas-state-reports/txfinaidDBSys",
    Layout: MainLayoutRoute,
  },
  {
    component: SpeedeCollegeTranscriptImport,
    exact: true,
    path: "/tools/import/registration/speede-college-transcript",
    Layout: MainLayoutRoute,
  },
  {
    component: Cbm009Graduation,
    exact: true,
    path: "/tools/export/texas-state-reports/cbm009-graduation",
    Layout: MainLayoutRoute,
  },
  {
    component: CensusStudentSchedule,
    exact: true,
    path: "/tools/export/texas-state-reports/cbm0csCensusStudentSchedule",
    Layout: MainLayoutRoute,
  },
  {
    component: RCSRecCourseSequence,
    exact: true,
    path: "/tools/export/texas-state-reports/rCSRecCourseSequence",
    Layout: MainLayoutRoute,
  },
  {
    component: Cbm002SuccessInit,
    exact: true,
    path: "/tools/export/texas-state-reports/cbm002-successinit",
    Layout: MainLayoutRoute,
  },
  {
    component: DegreeVerify,
    exact: true,
    path: "/tools/export/registration/degree-verify",
    Layout: MainLayoutRoute,
  },
  {
    component: TestScoreImp,
    exact: true,
    path: "/tools/import/admissions/test-scores-import",
    Layout: MainLayoutRoute,
  },
  {
    component: ProspectStudentImp,
    exact: true,
    path: "/tools/import/admissions/prospect-student-import",
    Layout: MainLayoutRoute,
  },
  {
    component: GlobalSearch,
    exact: true,
    path: "/tools/global-search",
    Layout: MainLayoutRoute,
  },
  {
    component: DataQuery,
    exact: true,
    path: "/tools/data-query",
    Layout: MainLayoutRoute,
  },
  {
    component: SSNMigration,
    exact: true,
    path: "/system-manager/ssn-migration",
    Layout: MainLayoutRoute,
  },
  {
    component: SearchPublisher,
    exact: true,
    path: "/system-manager/integrationplatform/publisher-event-setup",
    Layout: MainLayoutRoute,
  },
  {
    component: Amenities,
    exact: true,
    path: "/student-life/housing/properties/amenities",
    Layout: MainLayoutRoute,
  },
  {
    component: AddAmenities,
    exact: true,
    path: "/student-life/housing/properties/add-amenities",
    Layout: MainLayoutRoute,
  },

  {
    component: HousingPeriods,
    exact: true,
    path: "/student-life/housing/housing-periods",
    Layout: MainLayoutRoute,
  },
  {
    component: SearchForm,
    exact: true,
    path: "/tools/forms/:action?/:id?",
    Layout: MainLayoutRoute,
  },
  {
    component: SearchProperties,
    exact: true,
    path: "/student-life/housing/properties",
    Layout: MainLayoutRoute,
  },
  {
    component: AddProperties,
    exact: true,
    path: "/student-life/housing/properties/add-properties",
    Layout: MainLayoutRoute,
  },
  {
    component: ViewProperties,
    exact: true,
    path: "/student-life/housing/properties/view-properties",
    Layout: MainLayoutRoute,
  },
  {
    component: SearchMealPlan,
    exact: true,
    path: "/student-life/meal-plans",
    Layout: MainLayoutRoute,
  },
  {
    component: AddMealPlan,
    exact: true,
    path: "/student-life/meal-plans/add-meal-plan",
    Layout: MainLayoutRoute,
  },
  {
    component: ViewMealPlan,
    exact: true,
    path: "/student-life/meal-plans/view-meal-plan",
    Layout: MainLayoutRoute,
  },
  {
    component: Dashboard,
    path: "*",
    Layout: MainLayoutRoute,
  }
];
export default routesConfig;