import {
    BASIC_DETAILS_STEP,
    HOUSING_PERIOD_PROPERTY_OFFERINGS_STEP,
    MANAGE_HOUSING_PERIOD_PROPERTIES_MAIN_VIEW,
    MANAGE_HOUSING_PERIOD_PROPERTY_SUBSTEP,
    MANAGE_APPLICATIONS_SUBSTEP
} from '../../../pages/student-life-page/housing/housing-periods/housingPeriodConstants';
import {
    RESET_HOUSING_PERIOD_STATE,
    CLEAR_HOUSING_PERIOD,
    SET_HOUSING_PERIOD_BASIC_DETAILS,
    DELETE_HOUSING_PERIOD,
    DELETE_HOUSING_PERIOD_SUCCESS,
    DELETE_HOUSING_PERIOD_ERROR,
    ENABLE_DISABLE_HOUSING_PERIOD,
    ENABLE_DISABLE_HOUSING_PERIOD_SUCCESS,
    ENABLE_DISABLE_HOUSING_PERIOD_ERROR,
    LOAD_HOUSING_PERIOD_SELECT_ITEMS,
    LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR,
    LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS,
    SEARCH_HOUSING_PERIODS,
    SEARCH_HOUSING_PERIODS_SUCCESS,
    SEARCH_HOUSING_PERIODS_ERROR,
    LOAD_HOUSING_PERIOD,
    LOAD_HOUSING_PERIOD_SUCCESS,
    LOAD_HOUSING_PERIOD_ERROR,
    INITIALIZE_NEW_HOUSING_PERIOD,
    SAVE_HOUSING_PERIOD_BASIC_DETAILS,
    SAVE_HOUSING_PERIOD_BASIC_DETAILS_SUCCESS,
    SAVE_HOUSING_PERIOD_BASIC_DETAILS_ERROR,
    SET_MANAGE_HOUSING_PERIOD_PROPERTIES_SUBSTEP,
    INITIALIZE_HOUSING_PERIOD_PROPERTIES,
    INITIALIZE_HOUSING_PERIOD_PROPERTIES_SUCCESS,
    INITIALIZE_HOUSING_PERIOD_PROPERTIES_ERROR,
    GET_PROPERTIES_FOR_HOUSING_PERIOD,
    GET_PROPERTIES_FOR_HOUSING_PERIOD_SUCCESS,
    GET_PROPERTIES_FOR_HOUSING_PERIOD_ERROR,
    LOAD_HOUSING_PERIOD_PROPERTY,
    LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS,
    LOAD_HOUSING_PERIOD_PROPERTY_ERROR,
    CLEAR_HOUSING_PERIOD_PROPERTY,
    SAVE_HOUSING_PERIOD_PROPERTY,
    SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS,
    SAVE_HOUSING_PERIOD_PROPERTY_ERROR,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_SUCCESS,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_ERROR,
    INITIALIZE_NEW_HOUSING_PERIOD_PROPERTY_APPLICATION,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION,
    SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS,
    SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    CLEAR_HOUSING_PERIOD_PROPERTY_APPLICATION,
    ADD_HOUSING_PERIOD_PROPERTY_APPLICATION,
    ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS,
    ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION,
    DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS,
    DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR
} from './housingPeriodsActionTypes';

const initialState = {
    selectItems: {
        roommateQuestionnaires: [],
        moveInRules: [],
        propertyTypes: [],
        amenities: [],
        programs: [],
        yearClassifications: [],
        glossarySports: [],
        genders: []
        //residentTypes: [
        //    { displayText: "New", uniqueId: 1, isSelected: false },
        //    { displayText: "Returning", uniqueId: 2, isSelected: false }
        //]
    },
    search: {
        isLoading: false,
        results: []
    },
    addEditWizard: {
        isWizardVisible: false,
        activeStep: BASIC_DETAILS_STEP,
        housingPeriod: {
            housingPeriodID: 0,
            housingPeriodName: "",
            startDate: null,
            endDate: null,
            isActive: true,
            description: undefined,
            portalPublishStartDate: null,
            portalPublishEndDate: null,
            moveInRuleIDs: [],
            moveInChecklist: null,
            moveInCheckListName: "",
            roommateQuestionnaireID: null
        },
        housingPeriodProperties: [],
        editHousingPeriodProperty: {
            housingPeriodProperty: {
                housingPeriodPropertyID: 0,
                propertyName: "",
                propertyTypeID: null,
                isActive: 1,
                isParkingAvailable: null,
                isTransfers: null,
                isSmokingAllowed: null,
                isWheelchairAccessible: null,
                genderID: null,
                amenityIDs: [],
                programsIDs: [],
                yearClassificationIDs: [],
                glossarySportsIDs: []
            }
        },
        manageHousingPeriodPropertiesSubstep: MANAGE_HOUSING_PERIOD_PROPERTIES_MAIN_VIEW,
        selectedHousingPeriodPropertyID: 0,
        housingPeriodPropertyApplications: [],
        addEditHousingPeriodPropertyApplication: {
            isAddEditHousingPeriodPropertyApplicationVisible: false,
            housingPeriodPropertyApplication: {
                housingPeriodPropertyApplicationID: 0,
                housingPeriodPropertyID: undefined,
                applicationID: undefined,
                residentTypeID: undefined,
                frequencyID: undefined,
                transDocID: undefined
            }
        }
    }
};

const housingPeriodsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case RESET_HOUSING_PERIOD_STATE:
            return { ...initialState };
        case SET_HOUSING_PERIOD_BASIC_DETAILS:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriod: payload
                }
            };
        case CLEAR_HOUSING_PERIOD:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    isWizardVisible: false,
                    activeStep: BASIC_DETAILS_STEP,
                    housingPeriod: { ...initialState.addEditWizard.housingPeriod }
                }
            };
        case DELETE_HOUSING_PERIOD:
        case ENABLE_DISABLE_HOUSING_PERIOD:
        case LOAD_HOUSING_PERIOD_SELECT_ITEMS:
        case SEARCH_HOUSING_PERIODS:
        case LOAD_HOUSING_PERIOD:
        case SAVE_HOUSING_PERIOD_BASIC_DETAILS:
        case INITIALIZE_HOUSING_PERIOD_PROPERTIES:
        case GET_PROPERTIES_FOR_HOUSING_PERIOD:
        case LOAD_HOUSING_PERIOD_PROPERTY:
        case SAVE_HOUSING_PERIOD_PROPERTY:
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS:
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION:
        case ADD_HOUSING_PERIOD_PROPERTY_APPLICATION:
        case DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION:
        case SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: true
                }
            };
        case DELETE_HOUSING_PERIOD_SUCCESS:
            const housingPeriods = state.search.results.filter(x => x.housingPeriodID !== payload);
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                    results: housingPeriods
                }
            }
        case DELETE_HOUSING_PERIOD_ERROR:
        case ENABLE_DISABLE_HOUSING_PERIOD_ERROR:
        case LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR:
        case SEARCH_HOUSING_PERIODS_ERROR:
        case LOAD_HOUSING_PERIOD_ERROR:
        case SAVE_HOUSING_PERIOD_BASIC_DETAILS_ERROR:
        case INITIALIZE_HOUSING_PERIOD_PROPERTIES_ERROR:
        case GET_PROPERTIES_FOR_HOUSING_PERIOD_ERROR:
        case LOAD_HOUSING_PERIOD_PROPERTY_ERROR:
        case SAVE_HOUSING_PERIOD_PROPERTY_ERROR:
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_ERROR:
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR:
        case ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR:
        case DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR:
        case SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                }
            };
        case ENABLE_DISABLE_HOUSING_PERIOD_SUCCESS:
            const updatedHousingPeriods = state.search.results.map(hp => {
                if (hp.housingPeriodID === payload) {
                    return {
                        ...hp,
                        isActive: hp.isActive === "Yes" ? "No" : "Yes"
                    }
                }

                return { ...hp }
            });

            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                    results: updatedHousingPeriods
                }
            };
        case LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS:
            return {
                ...state,
                selectItems: payload,
                search: {
                    ...state.search,
                    isLoading: false
                }
            };
        case SEARCH_HOUSING_PERIODS_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    results: payload,
                    isLoading: false
                }
            };
        case LOAD_HOUSING_PERIOD_SUCCESS:

            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    isWizardVisible: true,
                    housingPeriod: payload
                }
            }
        case INITIALIZE_NEW_HOUSING_PERIOD:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    isWizardVisible: true,
                    housingPeriod: { ...initialState.addEditWizard.housingPeriod }
                }
            }
        case SAVE_HOUSING_PERIOD_BASIC_DETAILS_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriod: payload
                }
            }
        case SET_MANAGE_HOUSING_PERIOD_PROPERTIES_SUBSTEP:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    manageHousingPeriodPropertiesSubstep: payload
                }
            }
        case INITIALIZE_HOUSING_PERIOD_PROPERTIES_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriodProperties: payload
                }
            }
        case GET_PROPERTIES_FOR_HOUSING_PERIOD_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    activeStep: HOUSING_PERIOD_PROPERTY_OFFERINGS_STEP,
                    housingPeriodProperties: payload
                }
            }
        case LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    manageHousingPeriodPropertiesSubstep: MANAGE_HOUSING_PERIOD_PROPERTY_SUBSTEP,
                    editHousingPeriodProperty: {
                        ...state.addEditWizard.editHousingPeriodProperty,
                        housingPeriodProperty: payload
                    }
                }
            }
        case CLEAR_HOUSING_PERIOD_PROPERTY:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    editHousingPeriodProperty: { ...initialState.addEditWizard.editHousingPeriodProperty },
                    manageHousingPeriodPropertiesSubstep: MANAGE_HOUSING_PERIOD_PROPERTIES_MAIN_VIEW
                }
            };
        case SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    editHousingPeriodProperty: {
                        ...state.editHousingPeriodProperty,
                        housingPeriodProperty: payload
                    }
                }
            }
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    housingPeriodPropertyApplications: payload.housingPeriodPropertyApplications,
                    selectedHousingPeriodPropertyID: payload.selectedHousingPeriodPropertyID,
                    manageHousingPeriodPropertiesSubstep: MANAGE_APPLICATIONS_SUBSTEP
                }
            }
        case INITIALIZE_NEW_HOUSING_PERIOD_PROPERTY_APPLICATION:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: true,
                        housingPeriodPropertyApplication: {
                            ...initialState.addEditWizard.addEditHousingPeriodPropertyApplication.housingPeriodPropertyApplication,
                            housingPeriodPropertyID: payload
                        }
                    }
                }
            }
        case LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: true,
                        housingPeriodPropertyApplication: payload
                    }
                }
            }
        case SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        housingPeriodPropertyApplication: payload
                    }
                }
            }
        case CLEAR_HOUSING_PERIOD_PROPERTY_APPLICATION:
            return {
                ...state,
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: false,
                        housingPeriodPropertyApplication: { ...initialState.addEditWizard.addEditHousingPeriodPropertyApplication.housingPeriodPropertyApplication }
                    }
                }
            };

        case DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: true,
                        housingPeriodPropertyApplication: payload
                    }
                }
            }
        case ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS:
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                },
                addEditWizard: {
                    ...state.addEditWizard,
                    addEditHousingPeriodPropertyApplication: {
                        ...state.addEditWizard.addEditHousingPeriodPropertyApplication,
                        isAddEditHousingPeriodPropertyApplicationVisible: true,
                        housingPeriodPropertyApplication: payload
                    }
                }
            }

        default:
            return state;
    }
}

export default housingPeriodsReducer;