// Breadcrumbs - Tools
export const _newsFeed = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'News Feed', path: '/tools/elements-news-feed', isLink: false },
]
export const tools_addNewsFeed = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Elements News Feed', path: '/tools/elements-news-feed', isLink: true },
    { name: 'Add/Update News Feed', isLink: false },
]
export const AddProspect_Activity = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Admission Module', path: '/tools/processes/admissionsModule', isLink: true },
    { name: 'Add Prospect Activity', isLink: false },
]    
export const AddStudent_Activity = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Admission Module', path: '/tools/processes/admissionsModule', isLink: true },
    { name: 'Add Student Activity', isLink: false },
]   
export const nys_Siris = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'New York State Reports', path: '/tools/export/newyork-state-reports', isLink: true },
    { name: 'NYS SIRIS', isLink: false },
]
export const add_Details = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'New York State Reports', path: '/tools/export/newyork-state-reports', isLink: true },
    { name: 'NYS SIRIS', isLink: true },
    {name : 'Add/Update Contact Details', isLink: false}
]
export const tools_crsevaluationsetup =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Course Evaluation Setup', isLink: false }
]

export const tools_crsevaluationsetup_add =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Course Evaluation Setup', path: '/tools', isLink: true },
    { name: 'Add/Update Question', isLink: false }
]

export const tools_crsevaluationsetup_Mcitems =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Course Evaluation Setup', path: '/tools', isLink: true },
    { name: 'Add/Update MC Items', isLink: false }
]

export const tools_crsevaluationsetup_addForm =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Course Evaluation Setup', path: '/tools/crs-evaluation-setup', isLink: true },
    { name: 'Add/Update Form', isLink: false }
]
export const tools_GenerateExamIDs =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module', isLink: true },
    { name: 'Generate Exam IDs', isLink: false }
]
export const tools_GenerateExam_reports =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module', isLink: true },
    { name: 'Generate Exam IDs', path: '/tools/processes/registration-module/generate-ids', isLink: true },
    { name: 'Export', isLink: false}
]
export const tools_TranscriptBatches =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module', isLink: true },
    { name: 'Transcript Batches', isLink: false }
]
export const merge_offering = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module', isLink: true },
    { name: 'Merge Offering', isLink: false }
]
export const tools_sap = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module', isLink: true },
    { name: 'SAP', isLink: false }
]
export const add_tools_sap = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module', isLink: true },
    { name: 'SAP', isLink: false },
    { name: 'Add/Update SAP Criteria', isLink: false },

]
export const tools_BillingImport =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Billing', path: '/tools/import/billing', isLink: true },
    { name: 'Billing Import', isLink: false }
]
export const kheds_Export = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Kansas State Reports', path: '/tools/export/kansas-state-reports', isLink: true },
    { name: 'KHEDS Export', isLink: false },
]
export const tools_CODImport = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Financial Aid', path: '/tools/import/financial-aid', isLink: true },
    { name: 'COD Import', isLink: false },
]
export const tools_ISIRDirect = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Financial Aid', path: '/tools/import/financial-aid', isLink: true },
    { name: 'ISIR Direct', isLink: false },
]
export const tools_ISIRDirect_parameter = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Financial Aid', path: '/tools/import/financial-aid', isLink: true },
    { name: 'ISIR Direct', path: '/tools/import/financial-aid/isir-direct', isLink: true },
    { name: 'Add/Update School Parameter', isLink: false }
]
export const tools_DoctrackAutoLoad = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Financial Aid', path: '/tools/import/financial-aid', isLink: true },
    { name: 'Document Track Autoload', isLink: false },
]

export const tools_FacultyPictureLoad = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Picture Access', path: 'tools/picture-access', isLink: true },
    { name: 'Faculty Picture Load', isLink: false }
]
export const tools_StudentsPictureLoad = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Picture Access', path: 'tools/picture-access', isLink: true },
    { name: 'Student Picture Load', isLink: false }
]

export const add_CustomISIR = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Financial Aid', path: '/tools/import/financial-aid', isLink: true },
    { name: 'Document Track Autoload', isLink: true },
    {name : 'Add/Update Code in Custom ISIR', isLink: false}
]
export const add_StandardCode = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Financial Aid', path: '/tools/import/financial-aid', isLink: true },
    { name: 'Document Track Autoload', isLink: true },
    {name : 'Add/Update Document Tracking Load', isLink: false}
]
export const add_CodeISIR = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Financial Aid', path: '/tools/import/financial-aid', isLink: true },
    { name: 'Document Track Auto load', isLink: true },
    {name : 'Add/Update Code in  ISIR', isLink: false}
]
export const late_payment_fees = [
    {name: 'Tools', path: '/tools', isLink: true },
    {name: 'Processes', path: '/tools/processes', isLink: true },
    {name: 'Billing Module', path: '/tools/processes/billing-module', isLink: true },
    {name: 'Late Payment Fees', isLink: false}
]
export const late_payment_fineLookup = [
    {name: 'Tools', path: '/tools', isLink: true },
    {name: 'Processes', path: '/tools/processes', isLink: true },
    {name: 'Billing Module', path: '/tools/processes/billing-module', isLink: true },
    {name: 'Late Payment Fees', path: '/tools/processes/billing-module', isLink: true },
    {name: 'Student Fine Transaction Document', isLink: false}
]
export const tools_billingProcesses = [
    {name: 'Tools', path: '/tools', isLink: true },
    {name: 'Processes', path: '/tools/processes', isLink: true },
    {name: 'Billing Module', path: '/tools/processes/billing-module', isLink: true },
    {name: 'Billing Processes', isLink: false}
]
export const tools_texas_reports = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM008 Faculty', isLink: false },    
]
export const update_tools_texas_reports = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM008 Faculty', isLink: false },    
    { name: 'Update CBM008 Faculty', isLink: false }
]
export const tools_texas_student_schedule = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM00S Student Schedule', isLink: false },
]
export const tools_texas_student_schedule_update = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM00S Student Schedule', path: '/tools/export/texas-state-reports/cbmoos-student-schedule', isLink: true },
    { name: 'Update Details', isLink: false },
]
export const tools_texas_student_semester = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM0E1 End Semester Students', isLink: false },
]
export const tools_CODMessage = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Financial Aid', path: '/tools/import/financial-aid', isLink: true },
    { name: 'COD Message', isLink: false },
]
export const status_changes =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module/status-changes', isLink: true },
    { name: 'Status Changes', isLink: false }
]
export const add_status_changes =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module/status-changes', isLink: true },
    { name: 'Add/Update Status Changes', isLink: false }
]
export const speede_college_trans = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Registration', path: '/tools/export/registration', isLink: true },
    { name: 'Speede College Transcript', isLink: false },
]
export const speede_college_add_course = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Registration', path: '/tools/export/registration', isLink: true },
    { name: 'Speede College Transcript', path: '/tools/export/registration/speedee-college-transcript', isLink: true },
    { name: 'Update Course', isLink: false}
]
export const tools_ede_awards = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Financial Aid', path: '/tools/import/financial-aid', isLink: true },
    { name: 'EDE Awards', isLink: false },
]
export const applyTexasImport = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Admissions', path: '/tools/import/admissions', isLink: true },
    { name: 'Apply Texas Import', isLink: false },
]
export const tools_gainfulEmploymentExport = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Financial Aid', path: '/tools/export/financial-aid', isLink: true },
    { name: 'Gainful Employment Export', isLink: false },
]
export const test_score_Mapping = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Admissions', path: '/tools/import/admissions', isLink: true },
    { name: 'Test Scores Mapping', isLink: false },
]
export const add_testscore_Group = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Admissions', path: '/tools/import/admissions', isLink: true },
    { name: 'Test Scores Mapping', isLink: true },
    { name: 'Add/Update Test Score Mapping Group', isLink: false },
]
export const add_testscore = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Admissions', path: '/tools/import/admissions', isLink: true },
    { name: 'Test Scores Mapping', isLink: true },
    { name: 'Add/Update Test Score Mapping ', isLink: false },
]
export const mass_update_status = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module', isLink: true },
    { name: 'Mass Update Status', isLink: false }
] 
export const tools_powerfaids = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Financial Aid', path: '/tools/export/financial-aid', isLink: true },
    { name: 'PowerFAIDS', isLink: false },
]

export const tools_nslc_export = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Financial Aid', path: '/tools/export/financial-aid', isLink: true },
    { name: 'NSC Export', isLink: false },
]
export const tools_nslc_export_add = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Financial Aid', path: '/tools/export/financial-aid', isLink: true },
    { name: 'NSC Export', isLink: false },
    { name: 'Add/Update Ethnic Origins Mapping', isLink: false },

]

export const Mass_Update_Audit = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module', isLink: true },
    { name: 'Mass Update Audit', isLink: false }
]
export const tools_texas_reports_students = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM00A CE Students', isLink: false },
]
export const tools_texas_reports_students_census = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM0C1 Student Census', isLink: false },

]
export const cod_export = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Financial Aid ', path: '/tools/export/financial-aid', isLink: true },
    { name: 'COD Export', isLink: false },

]
export const tools_import_powerfaids = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Financial Aid', path: '/tools/imort/financial-aid', isLink: true },
    { name: 'PowerFAIDS', isLink: false },
]
export const return_to_title = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Financial Aid', path: '/tools/processes/financial-aid/r2t4', isLink: true },
    { name: 'R2T4', isLink: false }
]
export const Mass_Add_Audit = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Processes', path: '/tools/processes', isLink: true },
    { name: 'Registration Module', path: '/tools/processes/registration-module', isLink: true },
    { name: 'Mass Add Audit', isLink: false }
]
export const tools_texas_sequence = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'Recommended Course Sequence', isLink: false },
]
export const tools_texas_dbsys = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'Tx Fin Aid DB Sys', isLink: false },

]
export const tools_texas_censtdschedule = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM0CS - Census Student Schedule', isLink: false },

]
export const tools_texas_censtdschedule_update = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM0CS - Census Student Schedule', isLink: true },
    { name: 'Update Detail', isLink: false },


]
export const tools_texas_reports_classes = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM00C CE Classes', isLink: false },

]
export const Bulk_import = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Admissions', path: '/tools/import/admissions', isLink: true },
    { name: 'Bulk Import', isLink: false },
]
export const tools_canadian_asnimport = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Canadian Imports', path: '/tools/import/canadian-imports', isLink: true },
    { name: 'ASN Import', isLink: false },

]
export const tools_import_speedeCollegeTranscript = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Registration', path: '/tools/import/registration', isLink: true },
    { name: 'Speede College Transcript', isLink: false },
]
export const tools_texas_graduation = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM009 Graduation', isLink: false },
]
export const tools_texas_success = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Texas State Reports', path: '/tools/export/texas-state-reports', isLink: true },
    { name: 'CBM002 Success Initiative', isLink: false },
]
export const tools_export_degree_verify = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Registration', path: '/tools/export/registration', isLink: true },
    { name: 'Degree Verify', isLink: false },

]
export const tools_speedeDocumentlookup = [
    { name: 'Lookup', path: '/lookup', isLink: true },
    { name: 'Speede Document Lookup', isLink: false },
]
export const tools_edit_degree = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Registration', path: '/tools/import/registration', isLink: true },
    { name: 'Speede College Transcript', path: '/tools/import/registration/speede-college-transcript', isLink: true },
    { name: 'Update Student Degree', isLink: false },
]
export const tools_edit_address = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Registration', path: '/tools/import/registration', isLink: true },
    { name: 'Speede College Transcript', path: '/tools/import/registration/speede-college-transcript', isLink: true },
    { name: 'Update Student Address', isLink: false },
]

export const tools_edit_speedesession = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Registration', path: '/tools/import/registration', isLink: true },
    { name: 'Speede College Transcript', path: '/tools/import/registration/speede-college-transcript', isLink: true },
    { name: 'Update Speede Session', isLink: false },
]
export const tools_edit_courseinformation = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Registration', path: '/tools/import/registration', isLink: true },
    { name: 'Speede College Transcript', path: '/tools/import/registration/speede-college-transcript', isLink: true },
    { name: 'Update Course Information', isLink: false },
]
export const tools_edit_testInformation = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Registration', path: '/tools/import/registration', isLink: true },
    { name: 'Speede College Transcript', path: '/tools/import/registration/speede-college-transcript', isLink: true },
    { name: 'Update Test Information', isLink: false },
]
export const test_scores_Import = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Admissions', path: '/tools/import/admissions', isLink: true },
    { name: 'Test Scores Import', isLink: false },
]
export const prospect_student_Import = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Import', path: '/tools/import', isLink: true },
    { name: 'Admissions', path: '/tools/import/admissions', isLink: true },
    { name: 'Prospect/Student Import', isLink: false },
]
export const tools_globalSearch =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Global Search', isLink: false }
]
export const update_awards = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: 'Export', path: '/tools/export', isLink: true },
    { name: 'Financial Aid ', path: '/tools/export/financial-aid', isLink: true },
    { name: 'COD Export ', path: '/tools/export/financial-aid/cod-export', isLink: true },
    { name: 'Update Awards', isLink: false },

]

export const tools_edeAwards_AddAwardStatus = [
        { name: 'Tools', path: '/tools', isLink: true },
        { name: 'Import', path: '/tools/import', isLink: true },
        { name: 'Financial Aid', path: '/tools/import/financial-aid', isLink: true },
        { name: 'EDE Awards', path: '/tools/import/financial-aid/ede-awards', isLink: true },
        { name: 'Add/Update Award Status Mapping', isLink: false }

]
export const tools_search_student = [
    { name: 'Tools', path: '/financial-aid/student-search', isLink: true },
    { name: 'Search Student', isLink: false }
]

export const tools_dataQuery =[
    { name: 'Tools', path: '/tools', isLink: true},
    { name: 'Data Query', isLink: false }
]

export const forms_listing = [
    {action:"all",links:[{ name: 'Tools', path: '/tools', isLink: true },{ name: "Forms", path: "/tools/forms", isLink: true }]},
    { action:"add",links:[{ name: "Add Form", path: "/tools/forms/add", isLink: false }]},
    { action:"edit",links:[{ name: "Update Form", path: "/tools/forms/edit", isLink: false }]},
    { action:"view",links:[{ name: "View Form", path: "/tools/forms/view", isLink: false }]},
    { action:"",links:[{ name: "Search Forms", isLink: false }]},
];
  
export const add_update_forms = [
    { name: 'Tools', path: '/tools', isLink: true },
    { name: "Forms", path: "/tools/forms/add", isLink: true },
    { name: "Add/Update Forms", isLink: false },
];